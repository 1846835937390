/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "Montserrat";
  /*Can be any text*/
  src: local("Montserrat"),
    url("../fonts/Montserrat-Italic-VariableFont_wght.ttf") format("ttf");
}

@font-face {
  font-family: "Montserrat";
  /*Can be any text*/
  src: local("Montserrat"),
    url("../fonts/Montserrat-VariableFont_wght.ttf") format("ttf");
}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

:root {
  --portal-theme-primary: #e4c073;
  --portal-theme-secondary: #e4c07310;
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);
  --background-primary-color: black;
  --background-secondary-color: black;
  --sidebars-text-color: white;
  --sidebars-background-color: #1d1c1d;
  --popup-background-color: #000000;
  --popup-text-color: white;
  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: #fff;
  --input-background-secondary: #fff;
  --button-background-color: #1c1c1c;
  --button-hover-color: #e4c073;
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
  --input-border-color: rgba(145, 158, 171, 0.32);
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--background-primary-color) !important;
  color: var(--input-text-color) !important;
}

/* color scheme  */

/* side bar active color */

.css-zggrcv-MuiButtonBase-root-MuiListItemButton-root {
  color: var(--portal-theme-primary);
  background-color: var(--portal-theme-secondary);
}

.css-zggrcv-MuiButtonBase-root-MuiListItemButton-root:before {
  background-color: var(--portal-theme-primary);
}

.css-7tp7sf-MuiButtonBase-root-MuiListItemButton-root {
  color: var(--input-text-color);
}

.css-1mcnwpj-MuiList-root,
.css-76topu-MuiPaper-root-MuiDrawer-paper,
.css-340559 {
  background-color: var(--sidebars-background-color);
}

.css-1x1uhi3-MuiButtonBase-root-MuiListItemButton-root {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

/* pop up menu background color */
.css-1d9xd7g-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
.css-bgcvkz,
.css-1ft4iha,
.css-f0u5qy,
.css-13pz97u {
  background-color: var(--sidebars-background-color) !important;
  color: var(--input-text-color) !important;
}

.css-1bwuv8o {
  color: var(--input-text-color) !important;
}

.custom-popover {
  /* position: relative; */
}

.more-manu {
  position: absolute;
  bottom: 0%;
  left: -135px;
}

.css-18bh2x9-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

/* side bar drop down text color */
.css-15az7i7 {
  color: var(--input-text-color) !important;
}

.manu-position {
  position: absolute !important;
  top: 468px !important;

  right: 16px !important;
}

.autoSizeable {
  border: solid 1px white !important;
}

.autoSizeable:focus-visible {
  border: solid 1px green !important;
}

.autoSizeable:focus-within {
  border: solid 1px green !important;
}

.autoSizeable:active {
  border: solid 1px green !important;
}

.span-decoration {
  text-decoration: underline;
  margin-right: 5px;
  font-size: 12px;
}

.manu-place {
  padding-top: 13px;
  padding-right: 10px;
}

.email-color {
  color: var(--portal-theme-primary) !important;
}

.top-pedding {
  padding-top: 20px;
}

.avtar-setting {
  width: 35%;
  justify-content: space-between;
}

.date-setting {
  padding-top: 5px;
}

.shadow {
  box-shadow: rgba(77, 77, 77, 0.267) 0px 2px 8px 0px !important;
}

.date-Container {
  padding: 2px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
}

.folder-avtar-setting-container {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.set-optoin {
  justify-content: space-between;
}

.set-text {
  text-transform: capitalize;
}

.preview2 {
  position: relative;
}

.status {
  position: absolute;
  bottom: 20px;
  right: 40px;
  /* left: 341px; */
}
.MuiTablePagination-toolbar {
  align-items: baseline !important;
}
.paragraph-data {
  padding-top: 0.6rem !important;
  font-size: 16px;
}

.range-slider span {
  color: var(--portal-theme-primary) !important;
}

.goal_achieved_white {
  color: #fff !important;
  font-size: 20px;
}

.goal_achieved_white #date_text_string {
  color: var(--portal-theme-primary);
  color: var(--portal-theme-primary);
  font-size: 26px;
  margin-top: 4px;
}

.view {
  color: var(--portal-theme-primary);
  font-size: 16px;
  text-decoration: none;
}

.tracking_code_heading {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 7px;
  margin-left: 4px;
}

.Yes {
  text-transform: capitalize;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(34, 154, 22);
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgba(84, 214, 44, 0.16);
  font-weight: 700;
}
.No {
  text-transform: capitalize;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(232, 6, 6);
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgb(125, 16, 16, 0.5);
  font-weight: 700;
}
.preview2 span {
  position: absolute;
  right: -7px !important;
  top: -10px !important;
  font-size: 10px !important;
  font-weight: 700;
  height: 17px !important;
  z-index: 1;
  background-color: red !important;
  width: 16px !important;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}
.count-color {
  cursor: pointer !important;
  text-transform: capitalize;
  /* width: 66px !important; */
  height: 24px !important;
  border-radius: 50% !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  text-align: center !important;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 17px 16px;
  /* color: rgb(99, 115, 129); */
  color: black;
  font-size: 0.742857rem;
  font-family: Montserrat, sans-serif;
  background-color: var(--portal-theme-primary);
  font-weight: 700;
}
.small-contained-button-2 {
  padding-top: 11px !important;
  padding-bottom: 14px !important;
}
.tox-tinymce {
  border: 1px solid transparent;
}
.presentation nav ol li {
  cursor: pointer;
}
.calender-styyle {
  box-shadow: none !important;
}
.bar {
  z-index: 999999999999999999999999;
}

.capitalaize {
  text-transform: capitalize;
}

.icon-containers {
  position: absolute;
  top: 9px;
  right: 5% !important;
  cursor: pointer;
}

.modal-theme {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.filter-member-heading {
  text-transform: capitalize;
  margin-top: 20px;
  font-weight: 500;
  font-size: 22px;
}

.span-color {
  color: white;
  cursor: pointer;
}

/* input text color and dropdown */
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input,
.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1szfdds,
.css-1vghvaa {
  color: var(--input-text-color);
}

.css-1n7l3yx-MuiButtonBase-root-MuiListItemButton-root,
.css-16k078d-MuiButtonBase-root-MuiListItemButton-root,
.css-tr2x06-MuiButtonBase-root-MuiListItemButton-root,
.css-cnafzx-MuiButtonBase-root-MuiListItemButton-root,
.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color) !important;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  color: white !important;
  padding: 2.5px 4px 2.5px 8px;
}
.MuiInputBase-inputSizeSmall {
  color: white !important;
}
.css-1qzvkqg-MuiTypography-root-MuiDialogTitle-root {
  color: var(--portal-theme-primary);
}

.reply-anchor a:hover {
  text-decoration: none !important;
  color: white !important;
}

/* account popup icon color */
.css-hsw3r1 {
  color: var(--portal-theme-primary);
}

.css-vubbuv {
  fill: var(--portal-theme-primary);
}

.css-wj2255-MuiButtonBase-root-MuiButton-root:hover {
  background-color: var(--portal-theme-primary);
}

/* top sticky bar color */
.css-1yv00nn-MuiPaper-root-MuiAppBar-root,
.css-1nipdsk {
  background-color: var(--background-primary-color);
}

/* tables background color */
.css-n1f7dt-MuiPaper-root-MuiCard-root,
.css-oau2hf-MuiPaper-root,
.css-9dfmxx,
.css-1c1nuln {
  background-color: var(--sidebars-background-color);
}

/* table text color */
.css-qv3jka,
.css-qv3jka.Mui-active,
.css-193pwxe,
.css-sjdedl,
.css-1f4e4r3 {
  color: var(--input-text-color);
}

.css-184p1yy,
.css-zuwxiq {
  color: var(--input-text-color);
}

.css-9dfmxx {
  color: var(--input-text-color) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
  word-wrap: break-word;
}

.size-table {
  color: white;
  font-size: 14px;
}
.border-div {
  border-bottom: solid 0.3px rgb(207, 205, 205);
}
.width {
  width: 59%;
}
.css-2tnm32.Mui-selected {
  color: var(--input-text-color) !important;
}

.css-1m6yy9o {
  color: var(--input-text-color) !important;
}

.css-14ewge7:hover {
  color: var(--input-text-color) !important;
}

.css-2tnm32.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary) !important;
}

.css-40jehe:hover {
  background-color: var(--portal-theme-primary) !important;
}

/* calendar text color */
.css-1cwi9p6-MuiPaper-root {
  background-color: var(--sidebars-background-color) !important;
  color: var(--input-text-color);
}

.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root:hover {
  background-color: var(--portal-theme-primary);
}

.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-1nuss9t {
  color: var(--input-text-color);
}

.css-oau2hf-MuiPaper-root {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

/* table bottom text */
.css-qerafn-MuiTablePagination-root {
  color: var(--input-text-color);
}

/* table text color */
.css-1gf7s20-MuiTableCell-root,
.css-5hc1ig-MuiTableCell-root,
.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active,
.css-olh58z-MuiInputBase-root-MuiOutlinedInput-root,
.css-ph2jbk-MuiInputBase-root-MuiOutlinedInput-root,
.css-9w6cv0-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--input-text-color);
}

/* audio player color */
audio::-webkit-media-controls-panel {
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}

::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: var(--portal-theme-primary);
}

::-webkit-media-controls-enclosure {
  background-color: var(--portal-theme-secondary);
}

/* audio player color end */
/* three dots color 1st detail pages 2nd for tables */
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}

.css-i4bv87-MuiSvgIcon-root {
  color: var(--portal-theme-primary) !important;
}

/* tables popup menu color and background color */
.css-vuwtl7-MuiPaper-root-MuiPopover-paper,
.css-1uz9yhf {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

/* small arrow color on popup */
.css-98b6ta,
.css-4xuv6g {
  background: var(--sidebars-background-color);
}

/* account popover background color */
.css-1ti1ykt-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-1fuveia-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

/* confirmation popup */
.css-189n0i1-MuiPaper-root-MuiDialog-paper {
  background-color: var(--sidebars-background-color) !important;
}

/* sidebar icons color */
.css-1gxhmaf-MuiListItemIcon-root,
.css-r13mc8,
.css-1g0vwx4 {
  color: var(--portal-theme-primary);
}

/* sidebar background color */
.css-6lzitz-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
}

/* login page colors */
.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: var(--background-primary-color);
  color: var(--input-text-color);
}

.css-yzm7vx,
.css-1v6giby {
  color: var(--input-text-color);
}

.css-13d5z5v:hover {
  background-color: var(--portal-theme-primary);
}

.css-1v6giby {
  background-color: var(--background-primary-color) !important;
}

.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: var(--portal-theme-primary);
}

.card,
.qustion-section-accordion {
  background-color: var(--sidebars-background-color) !important;
}

/* color scheme end  */
/* autocomplete chip text color and popup background*/
.css-1esf93k-MuiButtonBase-root-MuiChip-root,
.css-11mo73z-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color);
}

.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary);
}

.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root {
  background-color: var(--portal-theme-primary) !important;
  color: var(--input-text-color) !important;
}

/* sidebar background color */
.css-6lzitz-MuiPaper-root-MuiDrawer-paper,
.css-1al2aw7 {
  background-color: var(--sidebars-background-color);
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.css-1rkgz8u {
  color: var(--input-text-color);
}

/* top sticky header */
.css-1nipdsk {
  color: var(--background-primary-color);
}

.css-1umppoh {
  background-color: var(--background-secondary-color);
}

.menuActive {
  color: var(--portal-theme-primary) !important;
  background-color: var(--portal-theme-secondary);
}

.responsive-iframe {
  /* position: absolute; */
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  width: 100%;
  /* height: 100%; */
}

.css-1c1nuln,
.css-1o0hybg-MuiDataGrid-root,
.css-1pacybd,
.css-1n1gvhf,
.css-1fki5np,
.css-cdr993-MuiTablePagination-root {
  color: var(--input-text-color);
}

/* support ticket text color */
.css-n1f7dt-MuiPaper-root-MuiCard-root {
  color: var(--input-text-color);
}

h1 {
  font-size: 40px;
  color: var(--portal-theme-primary);
}

h2 {
  font-size: 28px !important;
}

h3 {
  font-size: 1.2rem !important;
  font-weight: 700;
  margin-bottom: 0.5rem !important;
}

h4 {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

h5 {
}

h6 {
}

/* edit profile screen  */
.cards-edit,
.profile-cards {
  background-color: var(--background-secondary-color);
  border: none;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  cursor: pointer;
  transition: all 0.5s;
}

.cards-edit {
  width: 850px;
}

.edit-profile-icon-position {
  position: absolute;
  right: -10px;
  top: 66px;
}

.edit-profile-icon img {
  border-radius: 50px;
}

.edit-profile-icon {
  position: relative;
}

/* edit profile screen end */
.css-muvvuh {
  color: var(--input-text-color);
}

.css-ep587z-MuiTableCell-root,
.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root:hover,
.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root {
  color: var(--input-text-color);
}

.MuiDrawer-paperAnchorRight {
  width: 400px !important;
  max-width: 100%;
}

.feed-border {
  border: solid 1px gray;
}

.feed-background {
  background-color: var(--sidebars-background-color);
  height: 50px;
  padding-top: 10px;
  margin-top: 0;
}

.forms-drawer .simplebar-wrapper {
  height: 100% !important;
  padding-bottom: 20px;
}

.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}

.calender-events-text {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
}

.calender-events-box {
  color: var(--input-text-color) !important;
  padding: 20px;
}

.calender-events-box {
  margin-top: 15px;
}

/* calendar pop up bgcolor*/
.css-1cwi9p6-MuiPaper-root,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root {
  background-color: var(--sidebars-background-color) !important;
  color: var(--input-text-color);
}
.css-1h82tqn-MuiButtonBase-root-MuiPickersDay-root {
  background-color: black !important;
  color: white;
}
.css-vhv9do-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  background-color: #000 !important;
  border: 1px solid var(--portal-theme-primary);
  color: var(--portal-theme-primary);
}
.css-2tnm32:focus.Mui-selected {
  will-change: background-color;
  background-color: var(--portal-theme-primary) !important;
}

.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary) !important;
}

.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  will-change: background-color;
  background-color: var(--portal-theme-primary) !important;
}

/* text area text-color */
.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color) !important;
}

.Ninty-Day-trackerList-dots .remove-border,
.affirmation-dots .remove-border,
.set-display-inline .remove-border {
  /* color: hsla(40,31%,54%,.199); */
  color: var(--portal-theme-secondary);
  display: inline;
  height: 36px;
  position: absolute;
  right: 5px;
  text-align: center;
  width: 36px;
  top: 15px;
}

.a-style {
  text-decoration: none;
}

.a-style:hover {
  color: white !important;
}

.menu-background-circle {
  height: 35px;
  width: 35px;
  border-radius: 51px;
  /* background-color: #145b6c29; */
}

.answer-ticket {
  background-color: #007bff;
  color: white;
  width: 80px;
}

.solved-ticket {
  background-color: #28a745;
  color: white;
  width: 80px;
}

.trash-ticket {
  background-color: #ff0000;
  color: white;
  width: 80px;
}

.circle-image img {
  border-radius: 100%;
  cursor: pointer;
  height: 60px;
  padding: 9px;
  width: 60px;
  z-index: 1;
}

.preview img {
  border-radius: 5px;
  display: inline;
  height: 76px;
  margin-right: 10px;
  width: 76px;
}

.previews img {
  border-radius: 50px;
  display: inline;
  height: 100px;
  margin-right: 10px;
  width: 100px;
}

.pending-ticket {
  background-color: #ffc107d9;
  color: white;
  width: 80px;
}

.supportMemberBackground {
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  margin: 0px;
}

.new-memories .MuiFormControl-root {
  width: 100%;
}

.normal-text {
  font-size: 14px;
}

a:hover {
  text-decoration: none !important;
  color: var(--portal-theme-primary) !important;
}

/* change password screen */
.css-87t4q7 {
  background-color: var(--sidebars-background-color) !important;
}

.css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color) !important;
}

.modalIcon {
  cursor: pointer;
}
.css-1e0vrmd {
  background-color: var(--sidebars-background-color) !important;
}

.question-survey {
  margin-top: 11px;
  margin-left: 40px;
  font-size: 30px;
  cursor: pointer;
}
.question-survey-cross {
  margin-top: 11px;
  margin-left: 40px;
  font-size: 30px;
  fill: red;
  cursor: pointer;
}

legend {
  float: unset;
}

.text-area-task {
  background-color: black;
  outline: none;
  color: white;
}
.text-area-container .form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
  background-color: transparent;
  color: white;
}
.note-container {
  height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}
.color {
  color: var(--portal-theme-primary);
  font-size: 16px;
  text-transform: capitalize;
}
.booking-status-chip {
  width: 115px !important;
  padding: 0px 0;
  height: 29px;
}
.text-area-container .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: black;
  background-clip: padding-box;
  border: 1px solid var(--portal-theme-primary);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.programm-card h3 {
  color: var(--portal-theme-primary);
}

.programm-card p span {
  color: var(--text-disable);
}

.button_bottom_fix {
  position: fixed;
  right: 2%;
  bottom: 3%;
}

.lesson-card h4 {
  color: var(--portal-theme-primary);
}

.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}

.simplebar-content-wrapper {
  overflow: scroll;
}

.programme-card-desc-muted {
  position: relative;
}

.recording-card-date {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

.wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.div-style {
  border-bottom: 1px solid #cacaca;
  padding: 8px;
}

/* .css-69ikgk-MuiButtonBase-root-MuiButton-root:hover {
  background-color: var(--portal-theme-primary);
} */
.css-69ikgk-MuiButtonBase-root-MuiButton-root:hover,
.css-cplqmi:hover {
  background-color: var(--portal-theme-primary);
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.item-quantity,
.detail-item-quantity .item-quantity {
  display: flex;
  border: 1px solid var(--portal-theme-primary);
  justify-content: space-between;
  padding: 0;
  max-width: 92px;
  border-radius: 5px;
}

.detail-item-quantity .item-quantity {
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  margin-left: auto;
}

.item-quantity input,
.detail-item-quantity .item-quantity input {
  background-color: transparent;
  outline: none;
  border-left: 1px solid var(--portal-theme-primary);
  border-right: 1px solid var(--portal-theme-primary) !important;
  border-right: none;
  border-bottom: 0;
  color: var(--input-text-color);
  font-size: 13px;
  text-align: center;
}

.detail-item-quantity .item-quantity input {
  border-left: 1px solid var(--portal-theme-primary);
  border-right: 1px solid var(--portal-theme-primary);
}

.detail-item-quantity .item-quantity span {
  cursor: pointer;
}

.item-quantity-diabled {
  background-color: #4c4a4a;
  cursor: not-allowed !important;
}

.item-quantity span {
  width: 30px;
  text-align: center;
}

.member-profile-image {
  border-radius: 10px;
}

.perform-action-on {
  min-width: 400px;
  max-width: 100%;
}

.perform-action-on label {
  color: #fff;
}

.model-button-hover:hover {
  background-color: rgba(26, 147, 169, 0.08);
}
.model-cancel-hover {
  color: #ffff;
}
.model-button-hover:hover {
  background-color: rgba(26, 147, 169, 0.08);
}

.question-background {
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
}

/* .download-icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.resource-card {
  position: relative;
} */
/* autocomplete pop up menu background color */
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper,
.css-1clbjpy {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.download-icon .icon-style {
  color: black;
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 50px;
  right: 10px;
}

.download-icon {
  text-align: end;
  margin-bottom: 20px;
  margin-top: 20px;
}

.icon-style {
  float: right;
}

.menu-icon-resource {
  position: absolute;
  top: 0px;
}

.css-69ikgk-MuiButtonBase-root-MuiButton-root:hover {
  background-color: none;
}

.page-name_button {
  color: #00ab55;
  font-weight: 500;
  background-color: #f6f7f8;
  padding: 10px;
  border-radius: 5px;
}

.page-name_button:hover {
  color: #00ab55;
  font-weight: 500;
  background-color: #f6f7f8;
  padding: 10px;
  border-radius: 5px;
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

.css-1egl4wx {
  color: var(--input-text-color);
}

textarea:hover {
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
}

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 14px;
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-disable);
}

/* manage programme access */
.css-1o0hybg-MuiDataGrid-root {
  color: var(--input-text-color);
}

.css-cdr993-MuiTablePagination-root {
  color: var(--input-text-color);
}

.manage-program-chip-success {
  width: 83px;
  text-align: center;
}

.css-ia64ha-MuiTableCell-root {
  color: var(--input-text-color);
}

.anchor-style {
  text-decoration: none;
  cursor: pointer;
  color: var(--input-text-color);
}
.chat-search-box {
  position: relative;
}
.chat-search-icon {
  left: 25px;
  position: absolute;
  top: 13px;
}
.chat-search-input {
  background-color: transparent;
  border: 2px solid var(--portal-theme-primary);
  border-radius: 10px;
  color: #fff !important;
  height: 45px;
  outline: transparent;
  padding-left: 40px;
  width: 100%;
}
.orderDetail {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.detail-img {
  width: 55px;
  height: auto;
}
.imgDetail {
  display: flex;
}
/* .orderdes {
  margin-left: 10px;
} */
.orderdes h4 {
  color: var(--portal-theme-primary);
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 16px !important;
}
.product-container {
  max-height: 280px;
  height: 280px;
  overflow-y: auto;
}
.PriceBox span {
  font-size: 14px;
  color: rgb(201, 201, 201);
}
.finalPrice {
  color: var(--portal-theme-primary);
}
.font-size {
  font-size: 24px;
}
.orderdes p {
  font-size: 12px;
  margin-top: 7px;
  color: gray;
}
.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.tox .tox-statusbar a,
.tox .tox-statusbar__path-item,
.tox .tox-statusbar__wordcount {
  display: none;
  color: rgba(34, 47, 62, 0.7);
  text-decoration: none;
}

.card-button {
  /* position: absolute;
    bottom: 15px; */
}

.programme-content {
  padding: 15px 15px 20px 15px;
}

.icon-color {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.cursor-style {
  cursor: pointer;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: black;
}

.css-14ewge7,
.css-14ewge7:hover {
  background-color: var(--portal-theme-primary) !important;
}

.small-contained-button {
  background-color: var(--button-background-color) !important;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  padding: 8px 16px;
}

.small-delete-button {
  background-color: #e92b2b !important;
  border: 1px solid #e92b2b;
  border-radius: 5px;
  color: #fff;
  padding: 8px 16px;
  outline: none;
}

.small-outlined-button {
  background-color: #fff;
  padding: 8px 16px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
}

/* clock numbers color  */
.css-1nwnru8 {
  color: var(--input-text-color);
}

.css-1xvfevw {
  color: #ffffff6b;
}

.member-profile {
  /* overflow-x: ; */
}

.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.normal-font {
  font-size: 14px;
  font-weight: 500;
}

.feedback-programme-header {
  display: flex;
  justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.icon-style {
  color: var(--text-disable);
  float: right;
}

.icon-style:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
}

/* 
.icon-button-style{
    float: right;
} */

.lesson-card {
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  border-radius: 10px;
}

.css-1d9xd7g-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0 0 2px 0 rgba(169, 170, 192, 0.24),
    0 5px 10px -4px rgba(211, 211, 214, 0.24);
}

.back-screen-button {
  background-color: var(--back-arrow);
  margin-bottom: 10px;
}

.goal-button-color {
  color: black;
}

.goal-gallery {
  height: 200px;
  background-color: grey;
  border-radius: 10px;
}

.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color);
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}

.custom-video-player {
  height: 100% !important;
}

@media only screen and (max-width: 375px) {
  .custom-video-player {
    height: 100% !important;
  }

  .lesson-heading {
    padding-top: 10px;
  }

  .card-spacing {
    margin-top: 0.75rem;
  }

  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .lesson-card-image {
    height: 100%;
  }
}

/*=================================  full calender  =======================*/

.fc-scroller {
  overflow: hidden !important;
}

.calender-events-box {
  color: var(--input-text-color);
  padding: 0px;
}

.calender-events-text {
  margin-left: 0px;
  /* padding: 15px; */
  background-color: var(--portal-theme-primary);
}

.small-contained-chip-success {
  background-color: #2e7d32;
  border: 1px solid #2e7d32;
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  padding: 1px 6px;
}

.small-contained-chip-error {
  background-color: rgba(255, 72, 66, 0.16);
  border: 1px solid rgba(255, 72, 66, 0.16);
  color: rgb(183, 33, 54) !important;
  border-radius: 20px;
  color: #fff;
  font-size: 0.75rem;
  font-family: Montserrat, sans-serif;
  padding: 1px 6px;
}

.fc-toolbar-chunk {
  display: inline-flex;
}

.full-calendar-new-flow .fc-header-toolbar {
  display: none;
}

.toollbar-group-buttons .small-contained-button {
  border-radius: 0;
  color: #fff;
  outline: none !important;
  background-color: transparent !important;
  padding: 0.4em 0.65em;
}

.toollbar-group-buttons .small-contained-button svg {
  color: #fff !important;
  font-size: 16px;
}

.toollbar-group-buttons .small-contained-button:first-child {
  border-radius: 5px 0 0 5px;
}
.toollbar-group-buttons .small-contained-button:last-child {
  border-radius: 0 5px 5px 0;
}
.toollbar-group-buttons .selected-button {
  color: var(--portal-theme-primary);
}

.full-calendar-toolbar .today-text {
  opacity: 0.65;
  margin-right: 16px;
}

.fc-view-harness {
  min-height: 835px !important;
}

.calender-title {
  color: #fff;
}

.event-title-dot {
  height: 11px;
  width: 11px;
  margin-top: 6px;
  margin-right: 3px;
  border-radius: 50%;
}

/* chat card  */
.upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview span {
  position: absolute;
  right: 3px;
  top: -7px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}

.product-image-width {
  width: 100px !important;
}

.css-1l8cwz6-MuiToolbar-root,
.css-1r5qqep {
  background-color: var(--button-background-color);
}

.preview span:hover {
  cursor: pointer;
}

.preview {
  position: relative;
}
.cross-QR {
  position: absolute;
  right: 20px;
  top: 5px;
  height: 25px;
  width: 25px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
}

.css-17svla2-MuiButtonBase-root-MuiChip-root {
  color: var(--input-text-color);
}

.custom-popover-box .remove-border {
  right: 25px;
  width: 30px;
  position: absolute;
  top: 50px;
}

/* calendar add event */
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper,
.css-1uc7r6i,
.css-1uc7r6i {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.fc-theme-standard .fc-popover {
  background-color: var(--sidebars-background-color) !important;
}

.fc-theme-standard .fc-popover {
  z-index: 1 !important;
}

.css-nrrkmw {
  background-image: url(../images/BgImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.goal-gallery {
  height: 200px;
  background-color: grey;
  border-radius: 10px;
}

.css-16frrky-MuiPaper-root {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color) !important;
}

.css-1xvfevw {
  color: var(--input-text-color) !important;
}

.pagination-Color,
.css-19yphbx-MuiButtonBase-root-MuiPaginationItem-root {
  color: var(--input-text-color);
  text-align: right;
}

.fc-timegrid-event {
  background: none !important;
  border: none !important;
}

/* .fc{
  padding: 10px;
    border-top-style: solid;
    border-color: var(--portal-theme-primary);
} */
.css-eryebb {
  color: var(--input-text-color) !important;
}

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: #42969c;
}

.img_width_100 img {
  width: 100%;
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
  padding: 0px !important;
}

.fc-today-button {
  background: none !important;
  color: var(--input-text-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-right {
  background: none !important;
  color: black !important;
  /* border-style: none !important; */
}

.fc-prev-button {
  background: none !important;
  color: var(--input-text-color) !important;
  /* border-style: none !important; */
  border: solid 1px var(--portal-theme-primary) !important;
}

.fc-right {
  background: none !important;
  color: var(--input-text-color) !important;
  border-style: none !important;
}

.css-1aya31c {
  color: var(--input-text-color);
}

.css-15ynx34 {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-1m6yy9o {
  background-color: var(--sidebars-background-color);
}

.fc-next-button {
  background: none !important;
  color: var(--input-text-color) !important;
  /* border-style: none !important; */
  border: solid 1px var(--portal-theme-primary) !important;
}

.fc-dayGridMonth-button {
  background: none !important;
  color: var(--input-text-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  color: var(--input-text-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
}

.fc-timeGridDay-button {
  background: none !important;
  color: var(--input-text-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
}

/* .fc-col-header{
  border-bottom-style: solid !important;
}  */

.fc-col-header-cell-cushion {
  color: var(--input-text-color);
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

/* calendar day color end*/
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary);
}

.fc-scrollgrid {
  /* border-top: none !important;  */
}

/* .fc-day-sun {
  border-bottom:thick !important;
    border-right-style: hidden !important;
    padding: 10px;
} 

.fc-day-mon {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-tue {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-wed {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-thu {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-fri {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-sat {
  border-bottom: thick !important;
    border-right-style: hidden !important;
}  */

.css-1vhy9c5,
.css-vkp50w {
  color: var(--portal-theme-primary) !important;
}

.css-2tnm32 {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: var(--input-text-color);
  font-weight: bold;
}

.fc-daygrid-day-top {
  float: left !important;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}

.modalIcon {
  cursor: pointer;
}

.password_input label {
  margin-top: -7px;
}

.password_input .css-1nqe8vp.Mui-focused,
.password_input .css-5dtz1i-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  margin-top: 0px !important;
}

.password_input .radio_button p {
  margin: 10px 0px;
  font-size: 18px;
}

.fw_14 {
  font-size: 14px;
}

.password_input .radio_button span {
  color: white;
}

.preview span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}

.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}

.preview span:hover {
  cursor: pointer;
}

.preview {
  position: relative;
}

.css-17svla2-MuiButtonBase-root-MuiChip-root {
  color: var(--input-text-color);
}

.custom-popover-box .remove-border {
  right: 25px;
  width: 30px;
  position: absolute;
  top: 50px;
}

.css-n1f7dt-MuiPaper-root-MuiCard-root {
  color: var(--input-text-color);
}

.upload-button label {
  text-align: center !important;
  /* background-color: rgba(175, 151, 102, 0.199); */
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  /* color: var(--portal-theme-primary); */
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

/* manage program access */
.css-1o0hybg-MuiDataGrid-root {
  color: var(--input-text-color);
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.log-detail {
  width: 120px;
}

.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: flex;
}

.dl-button {
  /* border: thin; */
  border: 1px solid #ccc5c5;
  /* border-color: #ccc5c5; */
  padding: 5px;
  display: flex;
}

.dl-button2 {
  /* border: thin; */
  border: 1px solid black;
  /* border-color: #42969c; */
  padding: 5px;
  display: flex;
}

/* Upload file in import members */
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}

.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.files {
  position: relative;
}

.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.color input {
  background-color: #f1f1f1;
}

.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.program-exp {
  white-space: noWrap;
}
.program-exp:hover {
  color: var(--portal-theme-primary);
}

/* Upload file in import members end*/

/* ====================================== for ipad screen ============================== */

/* @media only screen and (max-width: 768px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */
/* ====================================== for iphone screen ============================== */

/* @media only screen and (max-width: 375px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */

/* pinterest layout design */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}

.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}

.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}

/* pinterest */
/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
  } */
/* h1 {
    color: coral;
  } */
.quotes-heading {
  margin-left: 11px;
  /* margin-bottom: 20px; */
  line-height: 1.235;
  margin-bottom: 25px;
}

.grid-container {
  columns: 3 200px;
  column-gap: 1.2rem;
  width: 95%;
  margin: 0 auto;
}

.grid-container div {
  width: 150px;
  margin: 0 1.5rem 1.5rem 0;
  display: inline-block;
  width: 99%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* padding: 0px; */
  /* box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%); */
  border-radius: 16px;
  transition: all 0.25s ease-in-out;
}

.grid-container div:hover img {
  filter: grayscale(0);
}

.grid-container div:hover {
  /* box-shadow: 5px 5px 5px rgba(117, 117, 117, 0.5); */
  cursor: pointer;
}

.grid-container div img {
  width: 100%;
  /* filter: grayscale(100%); */
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  transition: all 0.25s ease-in-out;
}

.grid-container div p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  /* padding-top: 9px; */
  text-align: left;
  /* font-style: italic; */
}

/* pinterest end */
@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-left: 10px;
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .quotes-heading {
    margin-left: 7px;
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }

  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }

  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .quotes-heading {
    margin-left: 6px;
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
  .calender-title {
    display: none;
  }
}

.scale-limit-heading {
  color: #fff;
}

.goal-statement-question {
  color: #fff;
}

.answer-history .heading {
  color: var(--portal-theme-primary);
  margin-bottom: 5px;
  font-size: 18px;
}

.fc-more-link,
.fc-button-active {
  color: var(--portal-theme-primary) !important;
}

.menus-list {
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--input-text-color);
  width: 100%;
}

.navbar-icon {
  color: var(--portal-theme-primary);
  margin-right: 16px;
}

/* .tox-tinymce-aux {
  display: none !important;
} */
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

.table-status span {
  padding: 2px 12px;
  font-size: 0.75rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  border-radius: 8px;
}
.QRcode p {
  color: white !important;
}

.mui-without-bg-custom-table {
  background-color: #000;
  border: 1px solid #fff;
}

.mui-without-bg-custom-table .MuiPaper-root,
.mui-without-bg-custom-table .Mui-selected,
.mui-without-bg-custom-table .MuiTableRow-hover:hover {
  background-color: #000;
}

.card-with-background {
  background-color: var(--sidebars-background-color);
  border: none;
}

.card-with-background .MuiPaper-root,
.card-with-background .Mui-selected,
.card-with-background .MuiTableRow-hover:hover {
  background-color: rgba(145, 158, 171, 0.08);
}

.mui-without-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
}

.mui-without-bg-custom-table .MuiCheckbox-root {
  padding: 0;
}

.mui-without-bg-custom-table .MuiPaper-elevation {
  background-color: transparent;
}

.mui-without-bg-custom-table .MuiOutlinedInput-root {
  border: 1px solid #8f8f8f;
}

.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: var(--portal-theme-primary);
  padding-left: 20px;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: 400;
}

.table-status .Active {
  color: rgb(34, 154, 22);
  background-color: rgba(84, 214, 44, 0.16);
}

.table-status .InActive {
  color: rgb(183, 33, 54);
  background-color: rgba(255, 72, 66, 0.16);
}

.mui-table-status span {
  padding: 2px 12px;
  font-size: 0.75rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  border-radius: 8px;
}

.mui-table-status .Active {
  color: rgb(34, 154, 22);
  background-color: rgba(84, 214, 44, 0.16);
}

.mui-table-status .InActive {
  color: rgb(183, 33, 54);
  background-color: rgba(255, 72, 66, 0.16);
}
.setting-card {
  background-color: var(--sidebars-background-color);
  padding: 20px;
  border-radius: 10px;
}

.mui-custom-table th,
td {
  white-space: nowrap;
}

.MUI-data-table {
  background-color: var(--popup-background-color);
}

.show_link_color a {
  color: var(--portal-theme-primary);
}

/* width */
.MuiTableContainer-root::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Handle */
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #636262;
  border-radius: 10px;
}

.sale-page-title {
  color: var(--portal-theme-primary);
  font-size: 23px;
  margin-left: 20px;
}
.sale-page-title_on {
  color: var(--portal-theme-primary);
  font-size: 23px;
}
.lesson-page-title {
  color: var(--portal-theme-primary);
  font-size: 18px;
  margin-left: 20px;
}

.show-preview-template span:hover {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.MuiSwitch-sizeMedium .MuiSwitch-track {
  background-color: var(--portal-theme-primary);
}

.all-fields-access .content-access {
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  line-height: 36px;
}

.feeds-pin-button span {
  background-color: var(--button-background-color) !important;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  padding: 8px 16px;
  cursor: pointer;
}

.confirmation-popup-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  padding: 16px 24px;
  color: var(--text-primary-color);
}

.confirmation-popup-title h2 {
  color: var(--portal-theme-primary);
  font-size: 25px;
}
.page-name {
  color: var(--portal-theme-primary);
}
.confirmation-popup-title p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

.nested-child-menu {
  background-color: #2a292af2;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
}

#fixedbutton {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 1;
}

/* .upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.affiliate_url_name {
  color: var(--portal-theme-primary);
}

.cursor-pointer {
  cursor: pointer !important;
}

.default_consultant_commission_input {
  width: 170px;
  color: #fff;
}

.default_consultant_commission_input .MuiInputBase-formControl {
  border: 1px solid #fff;
  color: #fff;
}

.default_consultant_commission_input span {
  color: var(--portal-theme-primary);
  margin-right: 5px;
}
.default_consultant_commission_inputs span {
  color: var(--portal-theme-primary);
  margin-right: 5px;
}

.slot-percentage-sign {
  color: var(--portal-theme-primary);
  margin-right: 5px;
}

.wheel-of-life-setting-section-heading h2 {
  color: var(--portal-theme-primary);
  margin-bottom: 0;
}
.wheel-of-life-setting-section-heading h3 {
  color: var(--portal-theme-primary);
  margin-bottom: 0;
  font-size: 24px !important;
}

.question-background {
  background-color: var(--sidebars-background-color) !important;
  /* Change BG color of date picker when opens upper side*/
}

.view-delegates-members span {
  color: var(--portal-theme-primary);
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

.view-delegates-members span:hover {
  text-decoration: underline;
}

.def-sale-section-count {
  background: #00ab55;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  /* min-width: 1rem; */
  max-height: 26px;
  padding: 4px;
  margin-top: 5px;
}

.sale-email-link {
  color: var(--portal-theme-primary);
}

.not-found-text {
  color: var(--input-text-color);
}

.button-style-remove {
  background-color: transparent !important;
  box-shadow: none;
  text-transform: capitalize;
}

.button-style-remove:hover {
  background-color: transparent !important;
}

.paper-color-leads,
.css-35q5go {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.paper-color-leads:hover {
  background-color: var(--portal-theme-primary);
  color: var(--input-text-color);
}

.selectedAssociateColor {
  background-color: var(--portal-theme-primary);
  color: var(--input-text-color);
}

.free {
  text-transform: capitalize;
  height: 25px;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  text-align: center;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: white;
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgba(24, 144, 255, 0.16);
  font-weight: 700;
}

.paid {
  text-transform: capitalize;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(34, 154, 22);
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgba(84, 214, 44, 0.16);
  font-weight: 700;
}

.normal {
  text-transform: capitalize;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  /* color: rgb(99, 115, 129); */
  color: white;
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgba(145, 158, 171, 0.16);
  font-weight: 700;
}
.csvButton {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.program-button-container {
  display: flex;
  align-items: center;
}
.program-button-container .small-contained-button {
  background-color: #1c1c1c !important;
  background-color: var(--button-background-color) !important;
  border: 1px solid var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  color: var(--portal-theme-primary);
  padding: 14px 16px;
}
.diary-icon-add {
  color: var(--portal-theme-primary);
  cursor: pointer;
}
.custom-popover {
  overflow: auto;
  overflow-x: hidden;
}
.justify-content-space-between {
  justify-content: space-between;
}
.counter {
  display: flex;
  margin-top: 28px;
  margin-left: 28px;
}
.MuiPopover-paper {
  background-color: var(--sidebars-background-color) !important;
  color: var(--input-text-color) !important;
}
/*
.custom-popover::-webkit-scrollbar-track {
  background: green;
}
.custom-popover::-webkit-scrollbar {
  width: 15px !important;
} */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--sidebars-background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.MuiPaginationItem-root {
  color: white !important;
  /* align-items: end; */
}
.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.pagination-space-from-bottom p {
  margin-bottom: 0px !important;
}
.transaction-filter {
  overflow-y: scroll !important;
}
.title-color {
  color: var(--portal-theme-primary) !important;
}
.textarea-autosize:focus-visible,
.new-textarea-autosize:focus-visible {
  border: 2px solid var(--portal-theme-primary) !important;
}

textarea {
  overflow: auto !important;
}

.new-textarea-autosize {
  width: 100%;
  height: 100px !important;
  background-color: transparent;
  color: white;
  border-radius: 10px;
  padding-top: 8px;
  padding-left: 5px;
  outline: none;
}

.coin-card {
  background-color: var(--sidebars-background-color) !important;
  padding: 20px;
  border-radius: 10px;
}

.react-tagsinput {
  /* background-color: #fff; */
  border: 1px solid rgb(86, 86, 86) !important;
  overflow: hidden;
  padding-left: 7px !important;
  padding-top: 11px !important;
  padding-bottom: 4px !important;
  border-radius: 7px !important;
  background: transparent !important;
}
.react-tagsinput-tag {
  background-color: var(--portal-theme-primary) 50;
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
  color: white;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}
.react-tagsinput--focused {
  border-color: var(--portal-theme-primary) !important;
}
.bagde-image {
  display: inherit;
  height: auto;
  width: 25px;
  margin-left: 10px;
}
.text-area-task {
  background-color: var(--input-background-secondary);
  outline: none;
}
.list-badge .bagde-image {
  margin-left: 5px;
  width: 25px;
  height: 27px;
}

.sidebar-search-input {
  width: 100%;
  border: solid 2px var(--portal-theme-secondary);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  outline: none !important;
}

.menus-search-input {
  border: solid 1px rgba(145, 158, 171, 0.32);
  height: 45px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  outline: none !important;
  border-radius: 5px;
  background-color: var(--sidebars-background-color);
  width: 100%;
}

.sidebar-search-box,
.menus-search-box {
  position: relative;
}

.sidebar-search-icon,
.menus-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #d6cfc170;
}

.appbar-search-icon {
  position: absolute;
  left: 9px;
  top: 10px;
  font-size: 16px;
  color: #fff;
}

.search_popover {
  position: absolute;
  top: 55px;
  left: 92px;
  background: var(--sidebars-background-color);
  max-height: 85vh;
  overflow-y: auto;
  width: 260px;
  border: 1px solid #775f2f;
  border-radius: 5px;
}

.search_popover .menus-list {
  padding-left: 20px;
}

.search_popover .child-menu {
  padding-left: 35px;
}

.appbar-search-input {
  width: 260px;
  border: solid 1px #af9766c4;
  border-radius: 5px;
  background-color: transparent;
  padding: 4px;
  padding-left: 30px;
  color: white;
  outline: none !important;
}

.appbar-search-input:focus {
  border: solid 1px var(--portal-theme-primary);
}

.menus-search-icon {
  top: 14px;
}

.css-1lenskg-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-7nuun.Mui-selected {
  color: var(--portal-theme-primary) !important;
}
@media (min-width: 600px) {
  .leads-search .css-obvsh9-MuiToolbar-root {
    padding-left: 24px;
    padding-right: 7px;
  }
}

.menus-child-items {
  padding-left: 30px;
  font-size: 16px;
}

.menu-dropdown-icon svg {
  float: right !important;
  margin-left: 15px;
}
.qustion-section-summary p {
  color: var(--portal-theme-primary);
  font-family: sans-serif !important;
}

.question-answer .MuiFormControlLabel-label {
  color: var(--text-primary-color);
}
.selectedAnswer {
  background-color: var(--portal-theme-secondary);
}

.star > button {
  background: transparent;
  border: 1.5px solid #b59d4e;
  border-radius: 50%;
  color: #b59d4e;
  display: inline-block;
  height: 28px;
  justify-content: center;
  margin: auto 3px;
  width: 28px;
  font-size: 13px;
}

.icon-style {
  color: var(--text-primary-color);
  float: right;
  width: 30px !important;
  height: 30px !important;
}
.icon-style-loader {
  color: var(--portal-theme-primary);

  width: 15px !important;
  height: 15px !important;
}
.icon-style:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
}
.program-hover:hover {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.view-more {
  color: var(--portal-theme-primary);
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s;
  text-transform: capitalize;
}
.view-more:hover {
  color: var(--portal-theme-primary);
  cursor: pointer;
  opacity: 8;
}
.icon-container {
  position: absolute;
  top: 12px;
  right: 5px;
  cursor: pointer;
}
.icon-container-card {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  font-size: 15px;
  text-align: center;
}
.custome-height {
  height: 145px;
  margin-bottom: 10px;
  overflow: auto;
}
.icon-container .MuiSvgIcon-fontSizeSmall {
  color: var(--portal-theme-primary);
}
.heading_programs {
  padding: 15px 9px 10px 7px;
  border-bottom: 1px solid rgb(66, 66, 66);
  font-weight: 500;
  color: var(--portal-theme-primary);
  padding-right: 30px;
}

.general-popup-model {
  position: relative;
  width: 560px;
  min-width: 520px;
  max-width: 100%;
  color: #fff;
}

.plan-detail-popup {
  width: 80%;
  max-width: 90% !important;
}

.react-tagsinput-input {
  color: white;
  font-size: 15px;
  margin-bottom: 5px;
  width: auto;
  margin-top: 0px;
}
.react-tagsinput-input::placeholder {
  color: #637381;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.general-popup-model hr {
  margin: 0.5rem 0;
  border: 1px solid #fff;
}

.general-popup-model .popup-title {
  padding: 0px 40px;
  color: #ffff;
  text-align: center;
}

.general-popup-model .popup-title h2 {
  font-size: 25px;
}

.general-popup-model .cross-icon {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--portal-theme-primary);
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.new-questions-statement {
  color: #919eab;
}

.accordion-summary .MuiAccordionSummary-contentGutters {
  color: #fff !important;
  display: block;
}

.button-link button {
  text-decoration: underline;
}
.history-image-icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.multiple-select span {
  color: white;
}
.history-coin-icon {
  width: 85px;
  height: 50px;
}
.css-1l8jb1w-MuiPaper-root-MuiPickersPopper-paper {
  background-color: var(--sidebars-background-color);
}
.programm-container {
  margin-top: 58px;
}
.main-menu-heading {
  color: var(--portal-theme-primary);
  text-transform: capitalize;
  font-weight: 600;
}

/* 
.MuiPickersPopper-paper button,
.MuiPickersPopper-paper .MuiPickersCalendarHeader-label {
  color: #fff;
} */

/* .MuiPickersDay-today,
.MuiPickersDay-today:hover,
.Mui-selected,
.Mui-selected:hover {
  background-color: var(--portal-theme-primary) !important;
} */

.mui-autocomplete .MuiChip-label {
  color: #fff;
}
.MuiAutocomplete-tag {
  color: #fff !important;
}
.MuiCalendarOrClockPicker-root {
  color: #fff !important;
}
.MuiClockPicker-root span {
  color: #fff;
}
.event-details {
  position: relative;
}
.event-title-dot {
  height: 11px;
  width: 11px;
  margin-top: 3px;
  margin-right: 3px;
  border-radius: 50%;
}

.calender-title {
  color: #fff;
}
.fc-daygrid-day-bottom {
  z-index: -1 !important;
}

.fc-daygrid-day-events {
  padding-top: 1rem;
  color: white;
  /* display: flex; */
  position: absolute !important;
  top: 10px;
}

.calender-events-text {
  color: white;
}
.calendar-week-card {
  background-color: var(--sidebars-background-color);
  border: 7px solid #000;
  border-radius: 13px;
}
.calendar-card {
  background-color: var(--sidebars-background-color);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.calendar-week-card {
  background-color: var(--sidebars-background-color);
  border: 7px solid #000;
  border-radius: 13px;
}

.calendar-week-card h4 {
  font-size: 14px;
  margin-bottom: 2px;
}

.calendar-card .card-event-day {
  padding: 5px;
  color: var(--portal-theme-primary);
  text-align: center;
}

.calendar-card .calendar-dot {
  height: 13px;
  width: 13px;
  margin: 0 6px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.calendar-card .card-event-title {
  padding: 5px;
}

.calendar-card .card-event-title:hover {
  color: var(--portal-theme-primary);
}

.calendar-card .card-event-title span {
  color: var(--text-secondary-color);
  font-size: 13px;
}

.calendar-card .week-event-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.notification-setting h3 {
  color: var(--portal-theme-primary);
}
.groups-list-questions h4 {
  color: var(--portal-theme-primary);
}
.groups-list-questions {
  color: #919eab;
}
.react-tagsinput-remove {
  color: red;
  text-decoration: none;
}

.page-subscribers-filter .small-contained-button {
  height: 40px;
  margin-top: 7px;
  padding-top: 5px;
}

.persons-list-billing {
  border: 1px solid #423f3f;
  border-radius: 10px;
}

.booking-text-color {
  height: 25px;
  width: 25px;
  border-radius: 50px;
}

.check-in-now-chip {
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.check-in-now-loading {
  height: 25px !important;
  width: 25px !important;
  margin: 0 auto;
}
.notification-statement p {
  color: #a1a1a1;
}

.react-phone-input,
.react-tel-input .country-list .search-box {
  width: 100% !important;
  background-color: transparent !important;
  color: #cacaca !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}

.react-phone-input:focus {
  box-shadow: none !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: rgba(246, 189, 75, 0.16) !important;
}

.react-tel-input .form-control {
  height: 40px;
}

.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown:hover,
.react-tel-input .selected-flag {
  background-color: transparent !important;
  background: transparent !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}
.country-list {
  max-width: 250px !important;
}
.react-tel-input .country-list .search-box {
  max-width: 94% !important;
  height: 34px;
}
.country-list,
.react-tel-input .country-list .search {
  background-color: var(--sidebars-background-color) !important;
}

.country-list .country:hover {
  background-color: rgba(246, 189, 75, 0.16) !important;
}

.Nurture_online {
  height: 15px;
  margin-top: 2px;
  color: green !important;
  fill: green !important;
}

.support_notification_sidebar_page {
  height: 15px;
  margin-top: 2px;
  color: #8d1616 !important;
  fill: #8d1616 !important;
}

.pointer span {
  cursor: pointer;
}

.pointer_cursor {
  cursor: pointer;
}

.table-phone-input .react-tel-input .form-control {
  height: 56px !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-size: 15px !important;
}

.table-phone-input .react-phone-input:focus {
  box-shadow: none !important;
  border: 2px solid var(--portal-theme-primary) !important;
}

.table-phone-input .react-tel-input .flag-dropdown {
  border: none !important;
}

.table-phone-input .react-tel-input .selected-flag {
  border: none !important;
}

.table-phone-input .react-tel-input .selected-flag .flag {
  left: 12px;
}

.transation-status-icon {
  height: 14px;
  width: 14px;
  margin-top: 4px;
  margin-right: 5px;
}

.succeeded_bg {
  color: #046307 !important;
  fill: #046307 !important;
}
.payment_failed_bg {
  color: #4b0505 !important;
  fill: #4b0505 !important;
}
.Initiated_bg {
  color: #808080d9 !important;
  fill: #808080d9 !important;
}
.refunded_bg {
  color: #117e99 !important;
  fill: #117e99 !important;
}
.partial_refunded_bg {
  color: #0f3cdf !important;
  fill: #0f3cdf !important;
}
.canceled_bg {
  color: #e4d612 !important;
  fill: #e4d612 !important;
}
.pending_bg {
  color: rgb(156, 39, 176) !important;
  fill: rgb(156, 39, 176) !important;
}
.requires_action_bg {
  color: #163dbd !important;
  fill: #163dbd !important;
}

.active_bg_program {
  color: #54d62c !important;
  fill: #54d62c !important;
}
.inactive_bg_program {
  color: #ff4842 !important;
  fill: #ff4842 !important;
}

.generating-pdf {
  padding-top: 0px;
  width: 400px !important;
  white-space: normal;
  font-family: serif;
  line-height: 11px;
}

.generating-pdf .logo-dd {
  max-width: 150px;
}

.generating-pdf .last-submit-date {
  font-size: 11px;
  margin-top: 3px;
}

.generating-pdf .general-question-heading {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.question-statement-for-pdf-text {
  width: 400px !important;
  white-space: normal;
  font-family: serif;
  line-height: 11px;
}

.question-statement-for-pdf-text p {
  margin-bottom: 10px !important;
}

.text-area-answer {
  white-space: normal;
  width: 400px !important;
  margin-bottom: 16px !important;
}

.generating-pdf,
.generating-pdf h4 {
  font-size: 9px !important;
  word-spacing: 0px !important;
  letter-spacing: 0.01px !important;
  white-space: nowrap !important;
  text-align: left;
  font-family: serif;
  line-height: 11px;
}

.generating-pdf h4 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.generating-pdf ul {
  padding: 0 !important;
  font-family: serif;
  line-height: 11px;
}

.generating-pdf .question-statement-for-pdf {
  white-space: nowrap !important;
  font-size: 9px !important;
  /* color: var(--portal-theme-primary); */
  margin-bottom: 4px !important;
}

.view-document-in-pdf {
  padding: 2px 5px;
  outline: none;
  /* border: 1px solid #000;
  color: #000; */
}

.generating-pdf .radio-buttons input {
  width: 8px;
  height: 8px;
  background-color: #fff;
  margin-top: 8px;
  margin-right: 4px;
}

.scaling-count {
  display: inline-block;
  width: 12px;
  height: 12px;
  text-align: center;
  border-radius: 50%;
  margin-right: 2px;
  border: 1px solid #000;
  background-color: #fff;
  padding: 0;
}

.scaling-count-selected {
  background-color: #000;
  color: #fff;
}

.scaling-count p {
  margin-top: -5px;
  font-size: 6px;
  margin-bottom: 0;
}

.generating-pdf input[type="checkbox"] {
  display: block;
  height: 8px;
  width: 8px;
  background-color: #000;
  margin-top: 8px;
  margin-right: 4px;
}

.font-weight-bold {
  font-weight: 700 !important;
}
.see-more-button {
  color: var(--portal-theme-primary);
  cursor: pointer;
  text-decoration: underline;
}

.question-statement-for-pdf-text span {
  font-size: 10px !important;
}

.MuiSvgIcon-root {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.user-profile-data h6 {
  margin-bottom: 0;
  font-weight: normal !important;
  opacity: 0.8;
}

.user-profile-data {
  margin-top: -95px !important;
  z-index: 0;
  position: relative;
}

.user-profile-data .profile_card {
  margin-top: 110px !important;
  margin-bottom: 40px;
}

.user-profile-data h2 {
  color: var(--portal-theme-primary);
  font-size: 18px !important;
  margin: 0px;
}
.user-profile-data h4 {
  font-size: 16px !important;
  font-weight: normal !important;
  margin: 0px;
}

.goal_statement_yes_icons img {
  height: 25px;
  margin-top: 23px;
  min-width: 25px;
}

.verification_note_t img {
  height: 15px;
  margin-left: 10px;
  min-width: 15px;
  margin-top: 4px;
}
.calendar-events-box {
  margin-top: 10px;
}
.calendar-event-title {
  color: #fff;
  margin-bottom: 5px;
  font-size: 13px;
}
.calendar-event-title:hover {
  color: var(--portal-theme-primary);
}
.calendar-events-text {
  color: white;
}
.fc-daygrid-day .start_time_for_popup {
  display: none;
}

.calendar-events-text {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}

.calendar_d_block .fc-daygrid-day-events {
  display: block !important;
}

.member_personal_chip .css-6od3lo-MuiChip-label {
  color: #fff !important;
}
#show-dialog-on-full .MuiDialog-paperScrollPaper {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  margin: 0 !important;
  position: relative;
}
.MuiFormControl-root {
  width: 100% !important;
}
.heading-calender {
  color: white;
}
.back-screen-button {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  margin-bottom: 10px;
}
.event-submit-button {
  background-color: var(--portal-theme-primary) !important;
  color: #fff !important;
}
.event-details h1 {
  font-size: 30px;
}
.icon-event-detail .close-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}
.icon-event-detail {
  font-size: 18px;
  right: 1%;
  top: 10px;
  justify-content: end;
}
.date-color {
  color: var(--text-secondary-color) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 3px;
}
.icon-event-detail .other-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}
.icon-event-detail .other-detail-icon svg {
  font-size: 16px !important;
}
.icon-event-detail .other-detail-icon:hover {
  background-color: var(--portal-theme-secondary);
}
.icon-event-detail .close-detail-icon svg,
.icon-event-detail .other-detail-icon svg {
  font-size: 16px !important;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
input[type="time"] {
  z-index: 0;
  position: relative;
}
input[type="time"]:after {
  content: "";
  background-image: url("../images/clock.png");
  height: 20px;
  width: 20px;
  background-size: contain;
  z-index: -1;
  position: absolute;
  right: 2%;
}
input[type="time"]:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABpklEQVR4nO2WTU4CQRCFZy9x5UIFY/iJchfFtegJEFwYvIg7MR6AKLpCvIl/p1Bwo0A+0/ImmRBgqmHY8ZJOOtOvul7XVHV1EKywggeAHaAGPAOvwLeGm3eAKpBZhuM00AD6xGMI3AG7STk/Arra/AdoAsfAPrCm4eZlrTkOsikt6vxcJ0KnyhpsckArEo3aIicfAgPgYgrnH1PW6rIdekeC0T8Pwz7ReZyAiAiHL2DLR8BtGPYY3kwB4jyI1vAptYGSKZuAgLz2chWUtgioad+mgRsrQDyXwA5nFgEdkcsJCjgVtW0R8CFyIUEB7p5weLMI6ImcsgqYhDFeSp97FgHdJQhYD8vRIuBd5L0gIQBF7fmSaBJa4ZuEVWsZeggIy7BiIWd0abjLI5eA84LXReQA3EhxK1gQwKP2uvYx2o5UQ30B55fa4xPY9DUuRdpxfU7nYTs+8LWP9oXwQeK6Wj6w/fMw7M62GmczE8Ch+jlKJpfRJ6rtlEZRpXYP/EbCPt/JxwFsAFfGR2lfD1j7A8TzpVQBntytpr7R07ytNVuprbBCMMIfUAVlwZ2ZLlcAAAAASUVORK5CYII=);
  background-size: contain;
  content: "";
  height: 20px;
  position: absolute;
  right: 2%;
  width: 20px;
  z-index: -1;
}
.general-popup-model-max-width {
  position: relative;
  max-width: 1000px;
  min-width: 1000px;
  z-index: 1200 !important;
}
.hide-index {
  z-index: 1200 !important;
}
.shadows {
  box-shadow: 0 0 2px 0 rgba(0, 3, 82, 0.24),
    0 16px 32px -4px rgba(0, 3, 82, 0.24);
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root:before {
  height: 0px;
}
.css-3ioe56::before {
  height: 0px !important;
}

.chat-message-chip span {
  color: #fff !important;
}
.solid-border {
  border: 1px solid #e8e9eb;
  padding: 10px 0px 10px 10px;
  margin-bottom: 10px;
  font-size: 15px;
}
.size-table-transaction {
  font-size: 14px;
}
.custom-modal {
  display: flex;
  justify-content: space-between;
}
.icon-button-modal {
  background-color: var(--portal-theme-secondary) !important;
  color: var(--portal-theme-primary) !important;
}

.stats-card {
  background-color: var(--sidebars-background-color);
  text-align: center;
  padding: 16px 0px;
  border-radius: 16px;
}

.stats-card-commission {
  box-shadow: rgb(185 185 185 / 10%) 0px 4px 12px !important;
}

.stats-card h6 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  opacity: 0.72;
}

.profile-card {
  background-color: var(--sidebars-background-color);
  text-align: center;
  padding: 16px 10px;
  border-radius: 16px;
  height: 100%;
}

.profile-card h6 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
}
.profile-card h3 {
  opacity: 0.8;
}

.stats-icon-box {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--portal-theme-primary);
  background-image: linear-gradient(
    135deg,
    rgba(183, 129, 3, 0) 0%,
    rgba(183, 129, 3, 0.24) 100%
  );
  border-radius: 50%;
  margin: auto;
  margin-bottom: 24px;
}
@media screen and (max-width: 750px) {
  .calendar-event-title {
    display: none;
  }
  .general-popup-model {
    position: relative;
    max-width: 560px;
    min-width: 100%;
  }
}

.profile_url {
  margin-top: 2px;
  margin-right: 5px;
  min-width: 120px;
}
.updated-date {
  position: absolute;
  right: 4%;
}

.members-navitems h6 {
  color: var(--portal-theme-primary);
  font-size: 15px;
}
.del_img_icon {
  position: relative;
  display: inline-block;
}

.open_image_view {
  position: relative;
}

.del_img_icon span {
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  width: 15px;
  background-color: red;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  z-index: 1;
}

.del_img_icon img {
  height: 50px !important;
}

.image_border {
  border: 1px solid #fff;
}

.open_image_view span {
  position: absolute;
  font-size: 25px;
  right: 6px;
  top: -6px;
}

/* ----------------------- */
.chat-theme {
  color: var(--text-primary-color);
}

.chat-section {
  height: calc(100vh - 110px);
}

.chat-left-part {
  height: calc(100vh - 110px);
  /* background-color: gray; */
}

.chat-right-part {
  background-color: var("--background-secondary-color");
  height: calc(100vh - 110px);
}

/* .chat-left-header {
  height: 100px;
} */

.chat-label {
  font-size: 20px;
  font-weight: 600;
}

.chat-new-icon {
  height: 30px;
  width: 30px;
  border: solid 1px black;
  border-radius: 50%;
  background-color: var(--background-secondary-color);
}

.chat-search-box {
  position: relative;
}

.chat-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}

.chat-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
}

.chat-search-input:focus {
  outline: none;
}

.chat-list {
  height: 65vh;
  overflow-y: scroll;
}

.chat-list-user {
  position: relative;
  border-bottom: solid 1px var(--background-secondary-color);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  color: var(--text-primary-color);
}

.selected-chat {
  background-color: var(--background-secondary-color);
}

.chat-profile-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chat-unread-count {
  position: absolute;
  right: 15px;
  top: 35px;
  background-color: var(--portal-theme-primary);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  color: var(--text-secondary-color);
}

.chat-unread-count-style {
  background-color: var(--portal-theme-primary);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  color: black;
}

.chat-last-time {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 10px;
  font-weight: 400;
  color: var(--text-secondary-color);
  width: 100%;
}

.chat-text-message {
  background-color: #1c1c1c;
}

.chat-incoming {
  background-color: #e4e6eb;
  color: black;
  border-radius: 10px 10px 10px 0px;
  max-width: 85%;
  /* max-width: 80%;
  min-width: 100px; */
  min-width: 130px;
  font-size: 14px;
  margin: 5px 10px;
  position: relative;
  padding: 5px 15px 15px 10px;
  overflow-wrap: break-word;
}

.chat-outgoing {
  background-color: #222c43;
  /* background-color: var(--sidebars-background-color); */
  color: white;
  border-radius: 10px 10px 0px 10px;
  max-width: 85%;
  font-size: 14px;
  margin: 5px 10px;
  position: relative;
  padding: 5px 15px 15px 10px;
  overflow-wrap: break-word;
  min-width: 130px;
}

.chat-incoming a,
.chat-last-message a,
.chat-outgoing a {
  color: var(--portal-theme-primary);
  font-weight: 500;
}

.chat-incoming a:hover,
.chat-outgoing a:hover {
  /* color: var(--portal-theme-primary); */
  text-decoration: underline !important;
}

.chat-message-space {
  padding-right: 100px;
}

.message-time {
  position: absolute;
  bottom: 2px;
  right: 10px;
  font-size: 10px;
}
.message-duration {
  position: absolute;
  bottom: 2px;
  left: 10px;
  font-size: 10px;
}

.control-dots .chat-message-dots {
  margin-top: 15px;
  visibility: hidden;
}

.control-dots:hover .chat-message-dots {
  margin-top: 15px;
  visibility: visible;
}

.chat-send-message-wrapper {
  /* position: absolute !important; */
  /* bottom: -10px; */
  /* height: 60px; */
  width: 100%;
  padding: 5px 10px;
  background-color: var(--background-primary-color);
  /* border-top: solid 1px var(--portal-theme-primary); */
  border-radius: 7px 7px 0px 0;
}

.chat-image {
  position: relative;
}

.chat-image .cross {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
  color: var(--portal-theme-primary);
  background-color: var(--background-secondary-color);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.chat-preview-image {
  height: 80px;
  width: 100px;
  border-radius: 5px;
  object-fit: cover;
}

.chat-send-input {
  width: 100%;
  /* border-radius: 25px; */
  padding: 9px 15px;
  padding-right: 30px;
  color: white;
  border-bottom-right-radius: 10px;
  border: none;
  border-bottom-left-radius: 10px;
  height: 200px !important;
}

.chat-send-input:focus {
  outline: none;
}

.user-profile {
  position: relative;
}

.user-profile .online {
  height: 8px;
  width: 8px;
  background-color: #057642;
  border-radius: 50%;
  position: absolute;
  bottom: 3%;
  right: 3%;
}

.user-profile .offline {
  height: 8px;
  width: 8px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
  position: absolute;
  bottom: 3%;
  right: 3%;
}

.border_r_16 {
  border-radius: 16px;
}

.user-profile .user-level {
  z-index: 1;
  height: 17px;
  width: 17px;
  position: absolute;
  bottom: -6%;
  left: -8%;
}

.chat-profile-name-padding {
  padding-right: 110px;
}

/* .user-profile .offline {
  display: none;
} */

/* User box css */

.user-box-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--background-secondary-color);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  z-index: 20;
}

.user-chat-box-body {
  position: relative;
  overflow: auto !important;
  height: 60vh;
  /* padding-bottom: 50px; */
  padding-top: 10px;
  transition: height 0.3s;
}

.theme-bottom-border {
  border-bottom: solid 1px var(--portal-theme-primary);
}

.user-box-header {
  padding: 0px 15px;
  height: 50px;
  border-bottom: solid 1px var(--portal-theme-primary);
  margin-bottom: 1px;
  cursor: pointer;
}

.user-box-header:hover {
  background: rgba(158, 163, 161, 0.08);
}

.hover-effect {
  cursor: pointer;
}

.hover-effect:hover {
  background: rgba(158, 163, 161, 0.08);
}

.user-box-heading {
  font-size: 14px;
  font-weight: 600;
}

.user-box-body-expand {
  overflow-x: scroll;
}

/* ------------ */
/* User chat box css */

.user-chat-box-header {
  padding: 0px 15px;
  height: 55px;
  border-bottom: solid 1px var(--portal-theme-primary) !important;
  cursor: pointer;
  background-color: transparent;
}

.user-chat-box-header:hover {
  background: rgba(158, 163, 161, 0.08);
}

.user-chat-box-wrapper {
  position: fixed;
  bottom: 0;
  right: 310px;
  z-index: 50;
  width: 400px;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--background-secondary-color);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
}

.chat-box-cross-icon {
  cursor: pointer;
}

.chat-box-cross-icon:hover {
  color: red;
}

/* ----------------- */
/* -------Right Part---------- */

.chat-right-wrapper {
  position: relative;
  height: 100%;
}

.chat-right-head {
  height: 70px;
  /* background-color: var(--background-primary-color); */
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--background-secondary-color);
}

.chat-right-bottom {
  /* position: absolute; */
  bottom: 0px;
  width: 100%;
}

/* --------------------------- */

.no-chat-wrapper {
  height: 60vh;
}

.no-chat-div {
  background-color: var(--background-secondary-color);
  padding: 30px;
  border-radius: 50%;
}

.no-chat-image {
  font-size: 100px;
  color: var(--portal-theme-primary);
}

.chat-formatters-box {
  padding: 0;
  width: 100%;
  height: 0;
  margin-bottom: 0;
  border-radius: 15px 15px 0 0;
  transition: height 0.3s;
}

.chat-formatters-border {
  border-top: 1px solid #6a6a6a99;
  border-left: 1px solid #6a6a6a99;
  border-right: 1px solid #6a6a6a99;
  border-bottom: 1px solid #6a6a6a99;
}

.chat-image-preview-box {
  background-color: var(--background-secondary-color);
  padding: 7px 10px;
}

.border-with-image {
  border-top: 1px solid #6a6a6a99;
  border-radius: 0 0 18px 18px;
}

.formatter-icon {
  height: 30px;
  width: 30px;
  /* background-color: var(--sidebars-background-color); */
  border-radius: 50%;
  text-align: center;
  padding-top: 3px;
  margin-left: 5px;
  cursor: pointer;
}

.show-hide-chat-formatter {
  position: absolute;
  bottom: 15px;
  right: 10px;
  cursor: pointer;
}

.upload-image-icon {
  background-color: transparent;
}

.pinned-comment {
  max-height: 150px;
  overflow: auto;
  border-bottom: 1px solid var(--portal-theme-primary);
}

.chat-tabs {
  display: flex;
}

.all-messages {
  border: 1px solid var(--portal-theme-secondary);
  margin: auto;
  border-radius: 15px;
  cursor: pointer;
  /* color: var(--portal-theme-primary); */
  padding: 3px 15px;
}

.selected-messages {
  background-color: var(--portal-theme-secondary);
  border: 1px solid var(--portal-theme-primary);
  color: var(--portal-theme-primary);
}

@media (max-width: 540px) {
  .user-chat-box-wrapper {
    right: 0;
  }

  .simplebar-content-wrapper {
    height: 70vh;
  }
  .chat-incoming audio,
  .chat-outgoing audio {
    width: 240px !important;
  }
}

.setIconPosition {
  position: absolute;
  top: -21px;
  right: 26px;
  cursor: pointer;
}
.setIconPositionEmail {
  position: absolute;
  top: -19px;
  right: 49px;
  cursor: pointer;
  color: var(--portal-theme-primary);
  font-size: 15px;
}
.small-contained-button-filter {
  background-color: var(--button-background-color) !important;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  padding: 0px 16px;
}
.button-history {
  text-align: end;
  margin-top: 11px;
}

.w-180px {
  width: 150px !important;
}
.button-commission {
  width: 150px;
}

.duplicate-icon {
  cursor: pointer;
}

.duplicate-icon-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navitems .upload-button label {
  text-align: center !important;

  background-color: var(--portal-theme-secondary);
  padding: 15px;

  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 39px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  position: absolute;
  top: 0px;
  right: -58px;
  /* z-index: 9999999; */
}
.menu svg {
  color: gray;
  fill: gray;
  /* z-index: 9999999; */
}
.menu_parent svg {
  color: gray;
  fill: gray;
}
.menu-box-row {
  position: relative;
}

.nav-item-box .MuiAccordionSummary-expandIconWrapper svg {
  /* margin-right: 45px; */
  /* margin-top: 10px; */
}
.nav-item-box .Mui-expanded svg {
  /* margin-left: 45px; */
  /* margin-top: 10px; */
}
.card-accordian {
  width: 91%;
}
.navitems svg {
  margin: 0px !important;
}
.menu_parent {
  position: absolute;
  top: 20px;
  right: 33px;
}
.nav-item-box {
  padding-left: 0px;
}

.select-all-menus {
  display: flex;
  cursor: pointer;
}

.page_del_btn {
  margin-bottom: 10px;
}

.navitems .preview img {
  width: 60px !important;
  height: auto;
}

.color-white {
  color: #fff !important;
}

.px-10 {
  padding-left: 100px;
  padding-right: 100px;
}

.full-page-popup {
  background-color: var(--sidebars-background-color);
  min-height: 100vh;
}

.diary-icon-remove {
  color: red;
  fill: red;
  margin-bottom: 0px;
  cursor: pointer;
}
.invoice-heading {
  font-size: 24px !important;
}
.hide-pagination .MuiTablePagination-displayedRows {
  display: none;
}
.hide-pagination .Mui-disabled .MuiSvgIcon-root {
  color: gray !important;
  fill: gray !important;
  cursor: not-allowed;
}

.static-textfield {
  border: 1px solid rgba(145, 158, 171, 0.32);
  position: relative;
  padding: 8.5px 14px;
  border-radius: 8px;
  cursor: text;
}

.static-textfield-label {
  position: absolute;
  left: 10px;
  top: -10px;
  font-size: 13px;
  color: rgb(99, 115, 129);
  background-color: var(--sidebars-background-color);
}

.static-textfield .field-value {
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
}

.static-textfield .disabled_label {
  color: rgb(99, 115, 129);
}

.dropdown-arrow {
  position: absolute;
  right: 0;
  top: 6px;
}

.select-menu-checkbox {
  width: 18px;
  height: 18px;
  border: 1px solid gray;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  outline: none;
}

.select-menu-checkbox.selected {
  background-color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
}

.select-menu-tick svg {
  position: absolute;
  height: 23px;
  width: 17px;
  top: -4px;
  color: #fff;
  fill: #000;
  left: 0;
}
.css-1kth6e6 p,
.css-cxxbca-MuiTableRow-root p {
  margin-bottom: 0 !important;
}

.transition-on-height {
  transition: height 3s ease;
}

.menu-search-input {
  width: 100%;
  background: transparent;
  outline: none;
  border-radius: 3px;
  padding: 5px 8px;
  border: 1px solid var(--audio-color);
  color: #fff;
}

.menu-search-box {
  background-color: var(--sidebars-background-color);
}

.menu-search-box svg {
  position: absolute;
  top: 9px;
  right: 5px;
  font-size: 18px;
  color: gray;
}

@media only screen and (max-width: 768px) {
  .navitems .preview img {
    width: 100% !important;
    padding: 0 !important;
  }
}

.default_consultant_commission_input {
  width: 170px !important;
  color: #fff;
}
.small-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordian_detail {
  background: black;
  border: 2px solid #1d1c1d;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.hide-focus-color .MuiAccordionSummary-root.Mui-expanded {
  background-color: var(--sidebars-background-color);
}

.hide-focus-color .mui-focusVisible {
  background-color: var(--sidebars-background-color);
}
.units {
  color: #637381;
  text-transform: uppercase;
}
.cart__border {
  border-top: 1px solid rgb(241, 243, 244) !important;
}
.table-main {
  border-radius: 5px;
  background-color: var(--background-secondary-color);
}
.cart__image-wrapper a {
  display: block;
}
.cart__image-wrapper {
  width: 120px;
  height: auto;
}
.cart td {
  padding: 10px;
}

.cart .cart__meta {
  padding-right: 15px;
}

.cart th.cart__meta,
.cart td.cart__meta {
  text-align: left;
}

.cart__image-wrapper {
  width: 120px;
  height: auto;
}
.cart th {
  font-weight: 400;
  padding: 10px 15px 8px;
  letter-spacing: 1px;
  color: white;
}
.price,
.detail-item-quantity {
  color: white;
}

/* .rewards-assigned-badges {
  display: flex;
  align-items: center;
} */

.badges-count {
  text-align: center;
}

.profile-badges-icon {
  height: 40px;
  width: 40px;
  display: inline;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* .badges-count {
  text-align: center;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--portal-theme-primary);
  margin-bottom: 5px;
} */

.rewards-assigned-badges img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

/* .Mui-disabled {
  cursor: not-allowed !important;
  color: var(--portal-theme-primary)50 !important;
}
.Mui-disabled svg {
  cursor: not-allowed !important;
  color: var(--portal-theme-primary)50 !important;
  fill: var(--portal-theme-primary)50 !important;
} */

.bread-crum-text {
  color: var(--text-primary-color) !important;
  text-decoration: none;
}

.active-bread-crum {
  color: var(--portal-theme-primary) !important;
  text-decoration: none;
}

.bread-crum-text:hover,
.active-bread-crum:hover {
  text-decoration: underline;
  color: var(--portal-theme-primary) 50 !important;
  fill: var(--portal-theme-primary) 50 !important;
}

#custom_mui_table img {
  cursor: pointer !important;
}
.css-16qf7rw {
  min-width: 450px !important;
}

.custome-radio-box {
  height: 18px;
  min-width: 18px;
  width: 18px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custome-radio-box .selected {
  height: 12px;
  width: 12px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
}

.event_calendar_box .MuiFormControl-root {
  width: 200px !important;
}
.question-survey-update {
  margin-top: 11px;
  margin-left: -15px;
  font-size: 30px;
  cursor: pointer;
}
.question-survey-cross-update {
  margin-top: 11px;
  margin-left: 0px;
  font-size: 30px;
  fill: red !important;
  cursor: pointer;
}

.bonus_type_input {
  width: 150px;
}
.custom-text-field {
  border: 1px solid rgba(118, 118, 118, 0.3);
  border-radius: 7px;
  padding: 15px;
  color: #919eab;
  font-size: 16px;
  text-transform: capitalize;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.custom-text-field .MuiSvgIcon-root {
  color: var(--portal-theme-primary) 50 !important;
  fill: var(--portal-theme-primary) 50 !important;
}
.custom-text-field .lable {
  position: absolute;
  font-size: 12px;
  top: -10px;
  left: 10px;
  background: #1d1c1d;
  padding-left: 3px;
  padding-right: 2px;
  color: var(--portal-theme-primary) 50 !important;
}
.main-accourdian {
  background: #1d1c1d;
  border-radius: 10px;
}

.accordian-summery {
  display: flex;
  align-items: center;
}

.expanded-content {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.collapsed-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.disabled-address {
  width: 100%;
  border: 1px solid var(--input-border-color);
  padding: 8px;
  border-radius: 8px;
  color: #919eab;
  cursor: not-allowed;
}
.autocomplete-input {
  width: 100%;
  padding: 8px;
  background-color: transparent;
  border: 1px solid var(--input-border-color);
  color: #fff;
  border-radius: 8px;
  outline: none;
}

.autocomplete-input:focus-visible {
  border: 2px solid var(--portal-theme-primary);
}

.autocomplete-input::placeholder {
  color: #fff;
}
.autocomplete-dropdown-container {
  position: absolute;
  top: 50px;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  background-color: var(--sidebars-background-color) !important;
}
.suggestion-item {
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 1px solid #000;
  border: 1px solid #c9bdbd;
  display: flex;
  background-color: var(--sidebars-background-color);
}
.suggestion-item--active {
  display: flex;
  background-color: #ccc;
  background-color: var(--portal-theme-secondary);
}

.suggestion-item span,
.suggestion-item--active span {
  font-size: 13px !important;
  margin-left: 1px;
  font-family: "NunitoSans";
  padding: 7px 0;
  display: block;
  cursor: pointer;
}

.suggestion-item b,
.suggestion-item--active b {
  font-size: 13px;
  padding: 7px 0px;
  padding-left: 7px;
  font-family: "NunitoSans";
  display: block;
  word-wrap: nowrap !important;
}

.suggestion-item .fa-location-dot,
.suggestion-item--active .fa-location-dot {
  display: block;
  padding-top: 10px;
  font-size: 13px;
  margin-left: 7px;
}
.address-heading {
  color: var(--portal-theme-primary);
}

.custome-radio-box {
  height: 18px;
  min-width: 18px;
  width: 18px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custome-radio-box .selected {
  height: 12px;
  width: 12px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
}
.calculating-custom-duty {
  height: 14px !important;
  width: 14px !important;
  color: var(--portal-theme-primary);
}

.show-free-plans-in-list-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.plans-in-list-info-list li {
  font-size: 13px;
}

.show-free-plans-in-list-info svg {
  cursor: help;
}

.scroll-top-community {
  position: fixed;
  bottom: -80px;
  font-size: 14px;
  background-color: var(--portal-theme-primary);
  color: #000;
  border-radius: 2px;
  right: 70px;
  cursor: pointer;
  z-index: 9999;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 1s ease;
}

.scroll-top-community .css-i4bv87-MuiSvgIcon-root,
.scroll-top-community .MuiSvgIcon-root {
  color: black;
  fill: black;
}

.manage-nav-items-config {
  outline: none;
  color: #fff;
  background-color: #141717;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  padding: 0 20px;
}

.manage-nav-items-config.colored-item {
  border: 1px solid var(--portal-theme-primary);
}
.info-icon {
  position: absolute;
  right: 6px;
  top: -25px;
}
.info-image {
  filter: invert(55%) sepia(50%) saturate(1500%) hue-rotate(20deg);
  width: 20px;
  display: inline-block;
  margin-right: 5px;

  margin-top: 16px;
  cursor: pointer;
}
.info-image-spacific {
  filter: invert(55%) sepia(50%) saturate(1500%) hue-rotate(20deg);
  width: 16px;
  display: inline-block;
  margin-right: 5px;

  margin-top: 16px;
  cursor: pointer;
}
.icons-position {
  position: absolute;
  right: 0px;
  top: -11px;
}
.access_type_field {
  width: 200px;
}

.app-header {
  flex-direction: row !important;
  justify-content: space-between;
  min-height: 62px;
  padding: 0px 40px;
  background-color: #000;
  color: #fff;
  box-shadow: none;
}

.app-sidebar-box,
.app-header,
.app-sidebar-box .MuiPaper-elevation {
  transition: all 0.5s ease !important;
}

.sidebar-permanent-drawer {
  transition: all 0.5s ease !important;
}

.full-calendar table {
  width: 100% !important;
}

.program-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 5px;
}

.program-dot.active-program {
  background-color: var(--portal-theme-primary) !important;
}

.appbar-close-icon {
  position: absolute;
  left: 90%;
  top: 7px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  max-width: 500px !important;
}

.select-box {
  margin-left: 27px;
  margin-right: 16px;
}

.affliate-id {
  font-size: 22px !important;
  margin-bottom: 0px !important;
}
.cross-icons {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--portal-theme-primary);
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}
.info-imagess {
  filter: invert(55%) sepia(50%) saturate(1500%) hue-rotate(20deg);
  width: 17px;
  display: inline-block;
  margin-right: 5px;
  margin-top: 16px;
  cursor: pointer;
  height: 16px;
}
.tableheadng {
  font-size: 16px;
  font-weight: 700;
  color: var(--portal-theme-primary);
}

.mission-status-chip {
  color: #fff;
}

.editor_chip {
  color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
}

.mui-date-picker-paper {
  background-color: var(--sidebars-background-color) !important;
}
.event-chat-emoji-picker-button img {
  width: 25px;
  height: 23px;
  cursor: pointer;
}
.password_radio span {
  color: white;
}

.ninety-day-history .MuiAccordion-root {
  border-radius: 10px !important;
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.mini-cards {
  position: relative;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  background: #141717;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15p;
  color: white;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
}
.diary-font-weight {
  font-weight: 400 !important;
  justify-content: space-between;
}

.ninety-day-history .MuiAccordion-gutters {
  border: 1px solid var(--portal-theme-secondary);
}

.total-earning-history {
  color: var(--portal-theme-primary);
}

.earning-description {
  color: lightgray;
  font-size: 15px;
}

.theme-color {
  color: var(--portal-theme-primary);
}

.history-status-dot {
  height: 10px;
  width: 10px;
  background-color: green;
  display: block;
  border-radius: 50%;
  margin-left: 10px;
}

.mission-status-chip {
  color: #fff;
}

.editor_chip {
  color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
}

.link-chip {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
  height: 31px;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.link-menu .menu-background-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: #ffda0014;
}

.mui-date-picker-paper {
  background-color: var(--sidebars-background-color) !important;
}
.note {
  background-color: #252525;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
}

.note-label {
  font-weight: bold;
  color: var(--portal-theme-primary);
}

.apexcharts-menu-item {
  color: #fff;
}

.apexcharts-menu.apexcharts-menu-open {
  background-color: var(--sidebars-background-color);
}

.apexcharts-toolbar {
  display: none !important;
}

.apexcharts-menu-icon svg {
  fill: #fff !important;
}

.rmdp-input {
  background: transparent;
  color: #fff;
  padding: 20px 10px 20px 0px;
  border: 1px solid var(--portal-theme-primary);
  cursor: pointer;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-left: 0;
}

.rmdp-input:focus {
  border: 1px solid var(--portal-theme-primary);
  border-left: 0;
}

.rmdp-arrow {
  border: solid var(--portal-theme-primary);
  border-width: 0 2px 2px 0;
}

.rmdp-week-day {
  color: var(--portal-theme-primary);
}

.rmdp-ep-shadow:after,
.rmdp-calendar {
  background-color: var(--sidebars-background-color);
}

.rmdp-ep-shadow:after {
  box-shadow: none;
  border: 1px solid var(--portal-theme-primary);
}

.rmdp-day,
.rmdp-header-values {
  color: #fff;
}

.rmdp-shadow {
  box-shadow: none;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 0px;
  position: absolute;
  left: -65px;
}

.rmdp-arrow-container:hover {
  background-color: var(--portal-theme-primary) !important;
  box-shadow: none;
}

.rmdp-ep-arrow[direction="top"] {
  border-bottom: none;
  left: 1px !important;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: var(--portal-theme-primary);
  color: #fff;
}

.rmdp-day.rmdp-today span {
  background-color: var(--portal-theme-primary);
}

.rmdp-range {
  background-color: var(--portal-theme-primary) 4d;
  box-shadow: none;
  color: #fff;
}

.date_icon {
  padding: 7px;
  border: 1px solid #c0c4d6;
  border: 1px solid var(--portal-theme-primary);
  border-start-start-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
}

.mui_table_title {
  padding: 21px;
  margin-bottom: 0px !important;
  font-size: 24px !important;
  color: var(--portal-theme-primary);
  padding-left: 15px;
}

.custom-table-sort-label {
  color: white !important; /* Force the text color to white */
}

.stats-container {
  display: flex;
  justify-content: space-around;
}

.stat-card {
  background-color: #1d1c1d;
  padding: 26px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1;
  /* margin: 0 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat-title {
  color: #a0a0a0;
  margin-bottom: 0px !important;
  font-size: 16px;
  font-weight: 600;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #fff;

  margin-bottom: 0px !important;
}

.negative-change {
  color: red;
}
.apexcharts-menu-icon {
  display: none !important;
}
.heading-subtitle {
  font-weight: 700;
  color: white !important;
}

.progress {
  align-items: center;
  display: grid;
}

.contents-added {
  color: white !important;
}

.b_radius_16 {
  border-radius: 16px !important;
}

.appbar-search-icon {
  position: absolute;
  left: 9px;
  top: 10px;
  font-size: 16px;
  color: #fff;
}

.search_popover {
  position: absolute;
  top: 55px;
  left: 92px;
  background: var(--sidebars-background-color);
  max-height: 85vh;
  overflow-y: auto;
  width: 260px;
  border: 1px solid #775f2f;
  border-radius: 5px;
}

.search_popover .menus-list {
  padding-left: 20px;
}

.search_popover .child-menu {
  padding-left: 35px;
}

.appbar-search-input {
  width: 260px;
  border: solid 1px #af9766c4;
  border-radius: 5px;
  background-color: transparent;
  padding: 4px;
  padding-left: 30px;
  color: white;
  outline: none !important;
}

.appbar-close-icon {
  position: absolute;
  left: 90%;
  top: 7px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.appbar-search-input:focus {
  border: solid 1px var(--portal-theme-primary);
}

.hover_div:hover {
  color: var(--portal-theme-primary);
}
.event-chat-emoji-picker-button img {
  width: 25px;
  height: 23px;
  cursor: pointer;
}

.border_left {
  border-left: 1px solid var(--portal-theme-primary);
  margin-left: -1px !important;
}

.index_style {
  margin-left: -12px;
}

.index_style span {
  background-color: var(--portal-theme-primary) 29;
  color: var(--portal-theme-primary);
  border-radius: 50px;
  width: 24px !important;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.straem_heading {
  padding: 20px;
  color: var(--portal-theme-primary);
}
.apexcharts-legend-text {
  color: white !important;
}

.flag {
  width: 20px;
  height: 13px;
  margin-left: 7px;
}

.download-icon {
  width: 40px;
}
.view-all-link {
  color: var(--portal-theme-primary);
  font-size: 12px;
  cursor: pointer;
  width: 100%;
  display: block;
  /* text-align: end;
  padding-right: 15px; */
}

.stats-icon-box-new {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  margin: auto;
  margin-bottom: 10px;
}
.manage-event-pixel-bg {
  padding: 2px 4px;
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
}
.membership-program-cross-button {
  fill: red !important;
  cursor: pointer;
}

.general-questions-list-drawer {
  width: 580px !important;
  max-width: 100% !important;
}
.disabled-field {
  cursor: not-allowed;
  opacity: 0.5;
}
.coin-manage-nav-items-config {
  white-space: nowrap;
  outline: none;
  color: #fff;
  background-color: #141717;
  /* height: 100%; */
  border-radius: 10px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  padding: 15px 5px;
}
.coin-manage-nav-items-config.colored-item {
  border: 1px solid var(--portal-theme-primary);
}
.general-questions-list-drawer {
  width: 580px !important;
  max-width: 100% !important;
}

.partition-border {
  background-color: var(--sidebars-background-color);
  padding: 13px 30px;
  border-radius: 10px;
}
.color_white {
  color: #fff;
}

.number_of_rewards_input input {
  width: 130px;
}
.rewards_popup {
  width: 450px;
  max-width: 450px;
  min-width: 450px;
  /* width: auto;
  max-width: min-content;
  min-width: min-content; */
}
.rewards_popup_view {
  width: 320px;
  max-width: 320px;
  min-width: 320px;
}

.badge-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191c21;
  border: 1px solid #555555;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.badge-card-remove {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #211919;
  border: 1px solid #780000;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.my-badge-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191c21;
  border: 1px solid #555555;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 260px;
}

.badge-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
}

.badge-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.badge-title {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.badges-count {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  margin-top: 6px !important;
  margin-left: 8px !important;
}
.history_delete_color {
  color: #da3030;
}
.reward-title {
  font-size: 20px;
}
.display-show-question {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border: 1px solid #8f8f8f;
  border-radius: 8px;
  font-size: 1rem;
}
.textField-in-table {
  width: 70px !important;
}
.question-type-label {
  font-weight: 500;
  margin-bottom: 4px;
  display: block;
}

.question-type-data {
  font-size: 14px;
  color: #b6b6b6;
}

.question-statement-label {
  font-weight: 500;
  margin-bottom: 4px;
  display: block;
}

.question-statement-data {
  font-size: 14px;
  color: #b6b6b6;
}

.coin-manage-nav-items-config-new {
  min-width: 234px;
  white-space: nowrap;
  outline: none;
  color: #fff;
  background-color: #141717;
  /* height: 100%; */
  border-radius: 10px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  padding: 15px 5px;
}
.coin-manage-nav-items-config-new.colored-item {
  border: 1px solid var(--portal-theme-primary);
}
.select-all-menus-new {
  display: flex;
}
.keyboard-arrow-down-icon-in-configuration {
  padding-top: 2px;
  margin-left: 5px;
  fill: #fff;
}
.keyboard-arrow-up-icon-in-configuration {
  margin-left: 5px;
  padding-top: 2px;
}
.capitalized {
  text-transform: capitalize;
}
.css-1uhfs8q-MuiPaper-root-MuiPickersPopper-paper {
  background-color: #1d1c1d !important;
}

.fix-button {
  position: fixed;
  right: 31px;
  bottom: 99px;
  z-index: 999;
}
.Sub-heading {
  margin-top: 20px !important;
  margin-left: 4px;
  color: var(--portal-theme-primary);
}

.detail-build-button {
  color: var(--portal-theme-primary);
  text-decoration: underline;
  cursor: pointer;
}
.card-notification {
  background-color: #292622 !important;
  border-radius: 10px;
}
.notification-update {
  font-size: 20px !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: #000;
}

.quest-access-expiry-box .MuiFormControl-root {
  min-width: 200px;
}

.custom-page-loader {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-page-loader .spinner-border {
  width: 40px;
  height: 40px;
  border: 0.22em solid var(--portal-theme-primary) !important;
  /* border: 0.22em solid #fff !important; */
  border-right-color: transparent !important;
}

.MuiCalendarPicker-root .MuiPickersDay-root {
  background-color: transparent !important;
  color: #fff !important;
}

.MuiCalendarPicker-root .MuiPickersDay-today {
  border: 1px solid var(--portal-theme-primary);
  color: var(--portal-theme-primary) !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: #000 !important;
}
.vertical-tabs {
  display: flex;
  .MuiTabs-scrollableY {
    background-color: var(--sidebars-background-color);
    width: 220px;
    margin-right: 20px !important;
    border-radius: 5px;
    /* height: 100vh; */
  }
  .mui_custom_vertical_tab {
    width: calc(100% - 214px);
    /* background-color: var(--background-secondary-color); */
    margin-top: 16px;
    border-radius: 10px;
  }
  .vertical_tabs_title {
    /* margin-top: 20px; */
    color: var(--portal-theme-primary) !important;
    margin-right: 20px;
    /* text-align: center; */
    margin-top: 26px;
    margin-bottom: 26px !important;
  }
}

.primary-color {
  color: var(--portal-theme-primary);
}

h3.primary-color {
  margin-bottom: 0 !important;
}

.highlight_div {
  color: var(--portal-theme-primary);
}
.border_color .MuiAvatar-root {
  border: 3px solid green;
}
.badge_level_dropdown_in_history_filter {
  width: 290px !important;
  /* text-align: center; */
}
.badge_level_dropdown_in_history_filter .MuiSelect-select {
  padding: 10px 15px !important;
}
.notification_contnet {
  color: var(--portal-theme-primary);
  font-size: 20px !important;
}
.cursor-block span {
  cursor: not-allowed !important;
}
.notification_contnet_setting {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
}

.user-activity-color-box {
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.user-activity-color-label {
  font-size: 14px;
}
.thirty-minutes-report {
  max-height: 380px;
  overflow: hidden;
}

.thirty-minutes-report h3 {
  text-transform: uppercase;
  color: lightgray;
  font-size: 14px !important;
}

.thirty-minutes-report-card {
  position: relative;
}

.active-users-chart-header h3,
.thirty-minutes-report-card .heading,
.retention-users-chart-header .heading,
.engagement-overview-card .heading {
  text-transform: uppercase;
  color: var(--portal-theme-primary);
  font-size: 15px !important;
  letter-spacing: 1.1px;
}

.active-users-chart-header h3 {
  margin-bottom: 0 !important;
}

.thirty-minutes-report hr {
  margin: 4px 0;
}

.height-100 {
  height: 100%;
}

.move-another-page {
  position: absolute;
  bottom: 10px;
  color: var(--portal-theme-primary);
  text-decoration: underline;
  right: 10px;
  cursor: pointer;
}

.move-another-page svg {
  font-size: 18px;
  margin-left: 5px;
}

.fixed-button {
  position: fixed;
  bottom: 40px;
  right: 24px;
}
.firebase-dashboard-stats h2 {
  color: var(--portal-theme-primary);
  font-size: 20px !important;
  letter-spacing: 1.1px;
}

.see-less-more {
  color: var(--portal-theme-primary);
  cursor: pointer;
  white-space: nowrap;
  margin-left: 10px;
}

.normalize-table-description {
  max-width: 300px;
  white-space: normal;
}

.information-icon {
  /* color: var(--portal-theme-primary); */
  color: #a9a9a9;
  font-size: 16px !important;
  margin-left: 6px;
  min-width: 16px;
}
.overview-stats {
  position: relative;
}

.overview-stats-icon {
  position: absolute;
  top: 3px;
  right: 9px;
  z-index: 1;
}
