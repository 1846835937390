/* Add gratitude button and text screen   */
.cards-space-affirmation {
  width: 18 rem;
  margin-top: 20 px !important;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.pods-cards-shadow {
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  border-radius: 16px;
}
.h2-heading {
  color: var(--portal-theme-primary);
}
.pods-active-members {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: -20px !important;
  color: #858585;
}
.pods-image {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.memory-heading {
  padding-top: -1px !important;
}

.padding-screen {
  padding-left: 12px;
  padding-right: 24px;
}
@media only screen and (max-width: 769px) {
  .cards-space-affirmation {
    width: 18 rem;
    margin-top: 20 px;
    margin-left: 65 px;
  }
}
.image-container {
  position: relative;

  overflow: hidden;

  height: 250px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  transition: border-color 0.3s ease;
}

.image-container:hover {
  border: 2px solid var(--portal-theme-primary); /* Change the border color on hover */
}

.image-container img {
  max-width: 100%;

  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
}
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.image-container:hover .overlay {
  opacity: 1;
}

.image-container.show-loader .overlay {
  opacity: 1;
}

.delete-icon {
  color: white;
  font-size: 1.2rem;
  margin: 5px;
}
.image-container-large {
  position: relative;
  border: 2px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.custom-download-item {
  color: var(--portal-theme-primary); /* Set the custom color value */
}
.download-top {
  position: absolute;
  top: 10px;
  right: 10px;
}
.icon-style-image {
  color: var(--text-primary-color);
  margin: 5px;
  width: 20px !important;
  height: 20px !important;
}

.icon-style-image:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
}
.css-9iedg7 {
  color: white;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
}

.loader > * {
  align-self: center;
}
.category-title {
  color: var(--portal-theme-primary);
  font-size: 25px;
  margin-left: 20px;
}
